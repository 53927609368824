:root{
    --muted-primary: #021e4c;
}
.invoice-card {
    padding: 15px;
    /* border: 2px solid var(--border-color);
    border-radius: 5px; */
}

.invoice-summary-data-label {
    font-size: medium;
    font-weight: bold;
}

.form-flex {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.field-floating-label>div {
    margin: unset;
    min-width: 280px;
}

.field-floating-label label {
    position: relative !important;
    top: 12px !important;
    margin-left: 16px !important;
    background: white !important;
    padding: 0px 4px !important;
    z-index: 10 !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

.field-floating-label > input {
    padding: 11px 11px 6px 11px !important;
}

.form-item-upload {
    margin-bottom: 15px;
    /* width: 50%; */
}

.form-item-submit {
    margin-bottom: 15px;
    display: flex;
    justify-content: end;
}

.badge-flex {
    display: flex;
    align-items: center;
}

.badge-flex>p {
    margin: unset;
}

.no-margin-top {
    margin-top: 0 !important;
}

.btn-invoice {
    margin-left: 35px;
    margin-top: 20px;
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important
}

.btn-invoice:hover {
    background-color: var(--muted-primary) !important;
}

.btn-invoice:disabled {
    background: var(--white-color) !important;
    border: 1px solid var(--border-color);
    color: var(--border-color) !important;
}

.btn-invoice-positioned {
    margin-left: 35px;
    margin-top: 20px;
    background: var(--white-color) !important;
    border: 1px solid var(--border-color);
    color: var(--border-color) !important;
}

.btn-invoice-positioned-errored {
    margin-left: 35px;
    /* margin-top: 20px; */
    background: var(--white-color) !important;
    border: 1px solid var(--border-color);
    color: var(--border-color) !important;
}

.custom-badge-container {
    display: flex;
    gap: 0.5rem;
    /* equivalent to gap-2 in Tailwind */
    margin-bottom: 1.5rem;
    /* equivalent to mb-6 in Tailwind */
    align-items: center;
    /* equivalent to items-center in Tailwind */
}

.custom-badge-number {
    padding: 0.25rem 0.75rem;
    /* equivalent to py-1 px-3 in Tailwind */
    border-radius: 9999px;
    /* rounded-full in Tailwind */
    background-color: #908c89;
    /* bg-badge in Tailwind */
    color: white;
}

.custom-badge-label {
    font-weight: 600;
    /* font-semibold in Tailwind */
    font-size: 1.25rem;
    /* text-xl in Tailwind */
}

.invoice-labels {
    margin-bottom: 35px;
}

.input-label {
    position: absolute;
    top: -12px;
    margin-left: 16px;
    background: white;
    padding: 0px 4px;
    z-index: 10;
    font: caption
}
.tree-node {
    text-align: center;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.node-content {
    display: inline-block;
    padding: 0.5em 1em;
    background-color: #fff;
    border: 2px solid var(--menuTitleColor);
    border-radius: 4px;
    position: relative;
    margin-top: 20px;
    /* font-family: Arial; */
}

.node-content::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    border-left: 2px solid var(--menuTitleColor);
    height: 1em;

}

.tree-node::before {
    content: '';
    position: absolute;
    border-top: 2px solid var(--menuTitleColor);
    width: 50%;
    top: 0;
}

.tree-node::before,
.tree-node::after {
    content: '';
    position: absolute;
    border-top: 2px solid var(--menuTitleColor);
    width: 50%;
    top: 0;
}

.node-content-children {
    display: inline-block;
    padding: 0.5em 1em;
    font-size: 18px;
    color: var(--black-color);
    border: 1px solid darkgray;
    border-radius: 20px;
    position: relative;
    margin-top: 20px;
    background-color: white;
    max-width: 100%;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
}

.node-content-parent {
    display: inline-block;
    padding: 0.5em 1em;
    font-size: 18px;
    color: var(--black-color);
    border: 1px solid darkgray;
    border-radius: 20px;
    position: relative;
    margin-top: 20px;
    max-width: 100%;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
}

/* .node-content-parent-label {
    color: var(--menuTitleColor);
} */

.tree-node::before {
    left: 0;
    border-right: 2px solid var(--menuTitleColor);
}

.tree-node::after {
    right: 0;
    border-left: 2px solid var(--menuTitleColor);
}

.tree-node:only-child::before,
.tree-node:only-child::after {
    display: none;
}

.tree-node:only-child {
    padding-top: 0;
}

.tree-node:first-child::before,
.tree-node:last-child::after {
    border: 0 none;
}

.tree-node:last-child::before {
    border-radius: 0 0 0 4px;
}

.tree-node:first-child::after {
    border-radius: 0 0 4px 0;
}

.children {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1em 0 0;
    position: relative;
}

.children::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    border-left: 2px solid var(--menuTitleColor);
    height: 1.11em;
}


.node-content-children::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    border-left: 2px solid var(--menuTitleColor);
    height: 1.1em;
    transform: translateX(-50%);
}

.node-content-parent .anticon,
.node-content-children .anticon {
    margin-left: 8px;
}

.node-content-children-label {
    font-size: 16px;
}

.node-content-children-formula {
    font-size: 15px;
}

.node-content-children-output {
    font-size: 14px;
    text-wrap: nowrap;
}

.pop-over-cell {
    padding: 8px;
    textAlign: left;
    border: 1px solid var(--black-color)
}

.pop-over-cell-value {
    color: var(--primary-color) !important;
}

.decision-details-value {
    color: var(--subheading-color) !important;
}

.decision-details-key {
    color: var(--black-color) !important;
    font-weight: bold;
}
.btn-positive {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important
}


.btn-positive:disabled {
    background: var(--white-color) !important;
    border: 1px solid var(--border-color);
    color: var(--border-color) !important;
}

.btn-negative {
    border-color: var(--primary-color) !important;
    color: var(--black-color) !important;
}
.summary-card {
    background-color: white;
    border: 1px solid darkgrey;
    border-radius: 8px;
    padding: 10px;
}

.summary-header {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.summary-title {
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
}



.summary-rows {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}

.summary-column {
    flex: 1 1 30%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.bold-text {
    font-weight: bold;
}
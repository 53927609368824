/* Custom Breadcrumb */
.a8-breadcrumb {
    background-color: #e6e6e6;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 8px 22px !important;
    border-radius: 50px;
    border: 1px solid #f0f0f0;
}

.a8-breadcrumb-title {
    color: var(--white-color);
    margin: 5px !important;
}

/* Custom Icon */
.a8-breadcrumb-icon {
    margin-right: 8px;
    font-size: 30px;
}
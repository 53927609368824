.custom-menu {
  padding-top: 8px !important;
  border: none !important;
  overflow: auto;
  height: calc(100% - 72px) !important;
}

.layout-slider {
  height: 100vh;
  background-color: var(--colorBgContainer);
  border-right: 1px solid var(--colorBgElevated);
}

.layout-slider-wrap {
  width: 200px;
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  background-color: var(--colorBgElevated);
  border-bottom: 1px solid var(--colorBorder);
}

.layout-slider-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: fixed;
  top: 64px;
  /* Ensure to specify units */
  z-index: 999;
}

.sider-arrow {
  border-radius: 0 !important;
  height: 100% !important;
  width: 100% !important;
  background-color: var(--colorBgElevated);
}

.sider-logo {
  height: 64px;
  background-color: var(--colorBgElevated);
  /* assuming token.colorBgElevated is a variable */
  font-size: 14px;
  border-bottom: 1px solid var(--colorBorder);
  /* assuming token.colorBorder is a variable */
  display: flex;
  align-items: center;
  width: 200px;
  /* Default width */
  padding: 0 16px;
  /* Default padding */
  justify-content: flex-start;
  /* Default justify-content */
}

.sider-logo.collapsed {
  width: 80px;
  padding: 0;
  justify-content: center;
}

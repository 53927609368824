.upload-invoice-table {
    width: max-content;
}

.upload-invoice-table-icon {
    cursor: pointer;
}

.input-buttons-wrapper {
    margin: 15px 0px 15px 0px;
    align-items: end;
}

.grid-component-wrapper {
    margin: 15px 0 15px 0px;
}

.grid-button {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important
}


.adjustment-link {
    color: var(--primary-color);
    cursor: pointer;
    margin-left: 5px
}

.drawer-buttons-wrapper {
    display: flex;
    justify-content: end;
    gap: 15px;
    margin-right: 25px;
    margin-top: 30px;
}

.field-floating-label label {
    position: relative !important;
    top: 12px !important;
    margin-left: 16px !important;
    background: white !important;
    padding: 0px 4px !important;
    z-index: 10 !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

.field-floating-label div {
    margin-bottom: unset !important;
}

.asterisk {
    color: var(--red-color);
    font-size: medium;
}

.form-wrapper {
    margin-left: 30px;
}

.temp-drawer {
    margin-bottom: 10px;
}

.back-arrow {
    border-radius: 50px;
    padding: 10px;
    background-color: var(--light-blue-color);
}

.back-arrow:hover {
    background-color: var(--secondary-color);
}

.back-arrow svg {
    height: 25px;
    width: 25px;
}

.table-td td {
    padding: 12px 16px !important;
}